<template>
    <div class="Section HeroSection">
        <div class="Content">
            <div class="Col">
                <h1>
                    Ihr zuverlässiger Partner für moderne Taxiverwaltung
                </h1>
                <span class="Subtitle">
                    Die Softwarelösung für die Taxibranche von morgen.
                </span>
                <br />
                <router-link to="/register">
                    <SiteButton>
                        Starten Sie Jetzt
                    </SiteButton>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import anime from 'animejs';
import SiteButton from '@/components/SiteButton';

export default {
    components: {
        SiteButton,
    },
    methods: {
        animateConnection() {
            var path = anime.path('.Motion-Path path');

            anime({
                targets: document.querySelector('.Circle'),
                translateX: path('x'),
                translateY: path('y'),
                rotate: path('angle'),
                easing: 'linear',
                duration: 3000,
                loop: true,
                direction: 'alternate',
            });
        },
    },
    mounted() {
        //this.animateConnection();
    },
};
</script>

<style lang="scss">
.HeroSection {
    min-height: 880px;
    color: var(--color-text-white);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: -80px;
    padding-top: 0px;
    position: relative;
    background-image: url('/banner.jpg');
    // background: linear-gradient(-42deg, color(blue-light) 0%, color(blue-dark) 100%);
    background-size: cover;
    background-position-x: 60%;
    @media (max-width: breakpoint(tabletPortrait)) {
        min-height: 600px;
        padding-top: 130px;
        // margin-top: -95px;
        margin-bottom: -60px;
    }
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-image: linear-gradient(266deg, #616c9f80 0%, #000000 100%);
        opacity: 0.8;
        mix-blend-mode: multiply;
    }

    .Content {
        @extend %sectionWrapper;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        position: relative;
        z-index: 1;
        margin-top: -80px;

        .Col {
            width: 20%;
            position: relative;

            &:first-child {
                width: 90%;
                padding-right: 120px;
            }

            @media (max-width: breakpoint(tabletPortrait)) {
                width: 100%;

                &:first-child {
                    width: 100%;
                    padding-right: 0;
                }
            }
        }
        img {
            width: 100%;
            max-width: 500px;
            opacity: 0;
        }
        .Subtitle {
            display: block;
            padding-top: 20px;
            font-weight: 400;
            max-width: 700px;
            line-height: 1.5em;

            @media (max-width: breakpoint(tabletPortrait)) {
                max-width: 100%;
            }
        }
    }

    h1 {
        margin: 0;
        font-weight: 700;
        margin-top: -50px;
        line-height: 1.25em;
        color: white;
        font-size: 4rem;

        @media (max-width: breakpoint(tabletPortrait)) {
            font-size: 2rem;
            margin-top: -30px;
        }

        span {
            display: block;
            color: white;
            font-weight: 200;
        }
    }
    .Subtitle {
        color: white;
    }

    .Motion-Path {
        position: absolute;
        top: 40px;
        left: 140px;
        display: block;
        opacity: 0;
    }

    .Circle {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 37px;
        left: 138px;
        background-color: var(--color-white);
        border-radius: 50px;
        opacity: 0;
    }
}
</style>
