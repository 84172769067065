var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Infobox",
    class: [{
      'is-active': _vm.isActive
    }, 'is-' + _vm.type]
  }, [_c('div', {
    class: 'is-' + _vm.type
  }, [_c('IconBase', {
    attrs: {
      "fill": _vm.svgFill,
      "width": "13",
      "height": "13"
    }
  }, [_vm.type == 'info' ? _c('IconInfo') : _vm._e(), _vm.type == 'warning' ? _c('IconWarning') : _vm._e()], 1), _c('h4', [_vm._v(_vm._s(_vm.title))])], 1), _c('p', [_vm._v(" " + _vm._s(_vm.message) + " ")]), _vm._t("default"), _vm.hasCloseButton ? _c('span', {
    staticClass: "Icon-close",
    on: {
      "click": _vm.closeModal
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }