var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Section Pricing",
    attrs: {
      "id": "pricing"
    }
  }, [_vm._m(0), _c('p', {
    staticStyle: {
      "margin-top": "50px",
      "font-size": "18px"
    }
  }, [_vm._v(" Es fallen 49€ einmalige Einrichtungsgebühr an. ")]), _c('p', {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v(" Alle Preise verstehen sich zzgl. MwSt. ")]), _c('div', {
    staticClass: "Price-Wrap"
  }, [_c('Card', {
    staticClass: "Price-Card"
  }, [_vm._v(" Alleinfahrende Einzelunternehmer "), _c('h3', [_vm._v(" 13€ "), _c('span', {
    staticClass: "Info"
  }, [_vm._v(" pro Monat / pro Taxi")])]), _c('ul', [_c('li', [_c('span', [_vm._v("Mitarbeiter und Fahrzeugverwaltung")])]), _c('li', [_c('span', [_vm._v("Einzelfahrtenaufzeichnung")])]), _c('li', [_c('span', [_vm._v("Schichtmanagement")])]), _c('li', [_c('span', [_vm._v("Betriebsnachweise")])]), _c('li', [_c('span', [_vm._v("Arbeitszeitnachweise")])]), _c('li', [_c('span', [_vm._v("SIM-Karte ohne Vertragsbindung")])])])]), _c('Card', {
    staticClass: "Price-Card Price-Card--highlight"
  }, [_vm._v(" Unternehmen "), _c('h3', [_vm._v(" 16€ "), _c('span', {
    staticClass: "Info"
  }, [_vm._v("pro Monat / pro Taxi")])]), _c('ul', [_c('li', [_c('span', [_vm._v("Mitarbeiter und Fahrzeugverwaltung")])]), _c('li', [_c('span', [_vm._v("Einzelfahrtenaufzeichnung")])]), _c('li', [_c('span', [_vm._v("Schichtmanagement")])]), _c('li', [_c('span', [_vm._v("Betriebsnachweise")])]), _c('li', [_c('span', [_vm._v("Arbeitszeitnachweise")])]), _c('li', [_c('span', [_vm._v("SIM-Karte ohne Vertragsbindung")])]), _c('li', [_c('b', [_c('span', [_vm._v("Integrierte taxiFusion Driver App")])]), _c('ul', [_c('li', [_c('span', [_vm._v("Pausenzeiten | ")])]), _c('li', [_c('span', [_vm._v("Fahrtmarkierungen und Anpassungen | ")])]), _c('li', [_c('span', [_vm._v("Statistiken und Auswertungen")])])])])]), _c('span', {
    staticClass: "Info"
  }, [_vm._v("Großkundenrabatt ")]), _c('p', {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v(" Ab 10 Autos 5% Rabatt | Ab 15 Autos 10% Rabatt. ")])])], 1), _c('div', {
    staticClass: "Modules"
  }, [_c('br'), _c('h4', [_vm._v(" Zusatzfunktionen für Ihr Geschäft. ")]), _c('div', {
    staticClass: "ModulesWrap"
  }, [_c('Card', [_c('h5', [_vm._v("Performance-Analyse")]), _c('p', [_vm._v(" Detaillierte Einblicke in die Leistung Ihrer Fahrer und Fahrzeuge für eine optimierte betriebliche Effizienz. ")])]), _c('Card', [_c('h5', [_vm._v("Erinnerungsfunktionen")]), _c('p', [_vm._v(" Erinnerungen für Reifen und Ölwechsel, Inspektionen, TÜV, P-Schein-Verlängerung und mehr. Verpassen Sie nie wieder wichtige Termine. ")])])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SectionHeader"
  }, [_c('p', [_vm._v(" Spezielle Pakete für Ihre Taxiflotte. ")]), _c('h2', {
    staticClass: "Headline"
  }, [_vm._v(" Flexible Lösungen ")])]);
}]

export { render, staticRenderFns }