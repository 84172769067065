<template>
    <div class="Infobox" :class="[{ 'is-active': isActive }, 'is-' + type]">
        <div :class="'is-' + type">
            <IconBase :fill="svgFill" width="13" height="13">
                <IconInfo v-if="type == 'info'" />
                <IconWarning v-if="type == 'warning'" />
            </IconBase>
            <h4>{{ title }}</h4>
        </div>
        <p>
            {{ message }}
        </p>
        <slot></slot>
        <span v-if="hasCloseButton" class="Icon-close" @click="closeModal"></span>
    </div>
</template>
<script>
import IconBase from '../icons/IconBase';
import IconInfo from '../icons/IconInfo';
import IconWarning from '../icons/IconWarning';

export default {
    components: {
        IconBase,
        IconInfo,
        IconWarning,
    },
    props: {
        title: String,
        message: String,
        type: {
            type: String,
            default: 'warning',
        },
        hasCloseButton: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            svgFill: '#fff',
            isActive: true,
        };
    },
    methods: {
        closeModal: function() {
            this.isActive = false;
            this.$emit('onClose');
        },
    },
};
</script>
<style lang="scss" scoped>
.Infobox {
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
    background-color: var(--color-white);
    position: relative;
    transform: scale(0.85);
    opacity: 0;
    transition: transform 0.095s, opacity 0.15s 0.3s, max-height 0.2s 0.4s, padding 0.2s 0.4s,
        margin 0.2s 0.4s;
    max-height: 0;
    will-change: transform;
    transform-origin: center;
    border-radius: 5px;
    margin-top: 20px;
    margin-right: 20px;
    padding: 20px;

    @media (max-width: breakpoint(tabletPortrait)) {
        margin: 10px;
        padding: 15px;
    }

    &.is-warning {
        border: solid 1px var(--color-red);
    }

    &.is-active {
        transform: scale(1);
        opacity: 1;
        max-height: 300px;
        margin-bottom: 40px;
    }

    > div {
        display: flex;
        align-items: center;

        &.is-info {
            svg {
                background-color: color(yellow-dark);
            }
        }

        &.is-warning {
            svg {
                background-color: color(red);
            }
        }
    }

    svg {
        background-color: color(yellow-dark);
        margin-right: 10px;
        padding: 7px;
        box-sizing: content-box;
    }

    h4 {
        font-weight: 700;
        display: inline-block;
        font-size: 1.25rem;
        margin-top: 6px;
        color: var(--color-text-blue);
    }

    p {
        font-size: 1rem;
        color: var(--color-blue-dark);
        line-height: 1.5;
        margin: 1rem 0;
    }

    a {
        color: var(--color-blue-dark);
        text-decoration: underline;
    }

    .Icon-close {
        background-color: var(--color-blue-light);
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        right: 20px;
        top: 20px;
        opacity: 1;
        transform: scale(1);
        will-change: transform;
        cursor: pointer;
        border-radius: 3px;

        &::before {
            position: absolute;
            width: 2px;
            height: 70%;
            content: '';
            transform: translate(-50%, -50%) rotateZ(45deg);
            left: 50%;
            top: 50%;
            background-color: var(--color-white);
        }

        &::after {
            position: absolute;
            width: 2px;
            height: 70%;
            content: '';
            left: 50%;
            transform: rotateZ(-45deg) translate(-120%, 10%);
            background-color: var(--color-white);
        }
    }
}
</style>
