import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index.js';
import toasted from 'vue-toasted';
import './registerServiceWorker';
import PortalVue from 'portal-vue';
import FloatingVue from 'floating-vue';
import VueMarkdown from 'vue-markdown';

import * as Sentry from '@sentry/vue';
import VTooltip from 'v-tooltip';
import windowResizeMixin from './lib/window-resize';

// Vue.mixin(windowResizeMixin);
Vue.use(VTooltip);
Vue.use(VueMarkdown);
Vue.use(toasted, {
    position: window.innerWidth > 768 ? 'bottom-left' : 'top-left',
    duration: 5000,
});

Vue.use(PortalVue);
Vue.config.productionTip = false;

Vue.use(FloatingVue);
const app = new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/taxifusion\.de/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});
