var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Section TestimonialsContainer"
  }, [_c('div', {
    staticClass: "TestimonialsHeading"
  }, [_c('p', [_vm._v("Wir haben mit hunderten von Kunden gearbeitet.")]), _c('h2', [_vm._v(" Was unsere Kunden über uns sagen. ")])]), _c('div', {
    staticClass: "TestimonialsContent"
  }, [_c('div', {
    staticClass: "TestimonialItem"
  }, [_c('blockquote', [_vm._v(" \"taxiFusion.de hat unsere Effizienz gesteigert. Die Plattform ist intuitiv und vereinfacht die Fahrtenverwaltung. Unsere Fahrer sind begeistert von der nutzerfreundlichen App.\" ")]), _c('div', {
    staticClass: "TestimonialAuthor"
  }, [_c('img', {
    attrs: {
      "src": "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=1024&h=1024&q=80",
      "alt": "Julia Schmidt"
    }
  }), _c('div', [_c('div', {
    staticClass: "AuthorName"
  }, [_vm._v("Julia Schmidt")])])])]), _c('div', {
    staticClass: "TestimonialItem"
  }, [_c('blockquote', [_vm._v(" \"Mit taxiFusion.de ist die Verwaltung unserer Flotte spielend leicht. Es bietet alles, was wir brauchen, in einem System.\" ")]), _c('div', {
    staticClass: "TestimonialAuthor"
  }, [_c('img', {
    attrs: {
      "src": "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      "alt": "Michael Bauer"
    }
  }), _c('div', [_c('div', {
    staticClass: "AuthorName"
  }, [_vm._v("Michael Bauer")])])])]), _c('div', {
    staticClass: "TestimonialItem"
  }, [_c('blockquote', [_vm._v(" \"Die cloud-basierte Lösung von taxiFusion.de ermöglicht es uns, unser Geschäft flexibel zu steuern. Daten sind sicher und immer aktuell.\" ")]), _c('div', {
    staticClass: "TestimonialAuthor"
  }, [_c('img', {
    attrs: {
      "src": "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      "alt": "Andreas Müller"
    }
  }), _c('div', [_c('div', {
    staticClass: "AuthorName"
  }, [_vm._v("Andreas Müller")])])])]), _c('div', {
    staticClass: "TestimonialItem"
  }, [_c('blockquote', [_vm._v(" \"taxiFusion.de unterstützt uns bei der Einhaltung gesetzlicher Anforderungen und vereinfacht unsere Arbeit erheblich.\" ")]), _c('div', {
    staticClass: "TestimonialAuthor"
  }, [_c('img', {
    attrs: {
      "src": "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      "alt": "Sarah Weber"
    }
  }), _c('div', [_c('div', {
    staticClass: "AuthorName"
  }, [_vm._v("Sarah Weber")])])])])])]);
}]

export { render, staticRenderFns }