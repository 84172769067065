<template>
    <div
        class="Skeleton"
        :class="[
            {
                'with-background': withBackground,
            },
        ]"
    >
        <div
            class="Skeleton-Row"
            :style="{ width: '70%', height: '80px', marginBottom: '60px' }"
        ></div>
        <div
            class="Skeleton-Row"
            :style="{ width: '100%', height: '250px', marginBottom: '40px' }"
        ></div>
        <div
            v-for="i in 5"
            :key="i"
            class="Skeleton-Row"
            :style="{ width: Math.min(Math.random() * 90 + 20, 100) + '%' }"
        ></div>
    </div>
</template>
<script>
export default {
    name: 'Skeleton',
    props: {
        withBackground: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.theme-dark {
    .Skeleton {
        .Skeleton-Row {
            background: var(--color-white);

            &::after {
                background-image: linear-gradient(
                    45deg,
                    rgba(255, 255, 255, 0.1),
                    rgba(255, 255, 255, 0.1),
                    rgba(255, 255, 255, 0.1)
                );
            }
        }
    }
}
.Skeleton {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    z-index: 101;
    top: 0px;
    left: 220px;
    width: calc(100vw - 220px);
    height: 100vh;

    &.with-background {
        background: var(--color-bg);
    }

    @media (max-width: breakpoint(tabletPortrait)) {
        padding: 30px 0px 30px 10px;
        left: 0px;
        width: 100vw;
        height: calc(100vh - 85px);
    }

    .Skeleton-Row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 35px;
        position: relative;
        background-color: rgb(217, 217, 231);
        overflow: hidden;
        border-radius: 4px;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%) translateZ(0);
            background-image: linear-gradient(
                45deg,
                rgba(255, 255, 255, 0.1),
                rgba(255, 255, 255, 0.2),
                rgba(255, 255, 255, 0.1)
            );
            animation: shimmer 3s infinite ease-out;
            content: '';
        }
    }
    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}
</style>
