var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SiteHeader",
    class: {
      'is-scrolled': _vm.isScrolled
    }
  }, [_c('div', {
    staticClass: "SiteHeader-Wrap"
  }, [_vm._m(0), _c('nav', {
    staticClass: "Navigation"
  }, [_c('a', {
    staticClass: "NavigationItem isActive",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("TaxiFusion")]), _c('a', {
    staticClass: "NavigationItem",
    attrs: {
      "href": "#features"
    }
  }, [_vm._v("Features")]), _c('a', {
    staticClass: "NavigationItem",
    attrs: {
      "href": "#pricing"
    }
  }, [_vm._v("Preise")]), _c('a', {
    staticClass: "NavigationItem",
    attrs: {
      "href": "#contact"
    }
  }, [_vm._v("Kontakt")]), _c('router-link', {
    staticClass: "NavigationItem",
    attrs: {
      "to": "register"
    }
  }, [_vm._v("Registrieren")]), _c('router-link', {
    attrs: {
      "to": "sign-in"
    }
  }, [_c('SiteButton', {
    attrs: {
      "size": "small"
    }
  }, [_vm._v("Login")])], 1), _c('div', {
    staticClass: "Hamburger",
    on: {
      "click": _vm.toggleMobileMenu
    }
  }, [_c('div'), _c('div'), _c('div')])], 1)]), _c('transition', {
    attrs: {
      "name": "slide-in"
    }
  }, [_vm.isMobileMenuOpen ? _c('div', {
    staticClass: "MobileMenu"
  }, [_c('div', {
    staticClass: "MobileMenuClose",
    on: {
      "click": _vm.closeMobileMenu
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"
    }
  })])]), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.closeMobileMenu
    }
  }, [_vm._v("TaxiFusion")]), _c('a', {
    attrs: {
      "href": "#features"
    },
    on: {
      "click": _vm.closeMobileMenu
    }
  }, [_vm._v("Features")]), _c('a', {
    attrs: {
      "href": "#pricing"
    },
    on: {
      "click": _vm.closeMobileMenu
    }
  }, [_vm._v("Preise")]), _c('a', {
    attrs: {
      "href": "#contact"
    },
    on: {
      "click": _vm.closeMobileMenu
    }
  }, [_vm._v("Kontakt")]), _c('router-link', {
    attrs: {
      "to": "register"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.closeMobileMenu.apply(null, arguments);
      }
    }
  }, [_vm._v("Registrieren")]), _c('router-link', {
    attrs: {
      "to": "sign-in"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.closeMobileMenu.apply(null, arguments);
      }
    }
  }, [_vm._v("Login")])], 1) : _vm._e()])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "Logo",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/svg/logo.svg")
    }
  })]);
}]

export { render, staticRenderFns }