<template>
    <div class="App">
        <SiteHeader />
        <HeroSection />
        <ProblemAwareness />
        <Solution />
        <Credentials />
        <Features />

        <!-- <Testimonials /> -->
        <!-- <CTA size="large">
            <h2>
                <span>Benötigen Sie ein Taxameter?</span>
                <br />
                <br />
                Nutzen Sie <a target="_blank" href="https://digitax-berlin.de/">Digitax</a>, unser
                Partner des Vertrauens
            </h2>

            <p>
                Wir arbeiten eng mit zertifizierten Herstellern von Fiskaltaxametern zusammen.
                Dadurch sind wir in der Lage Ihnen Geräte anzubieten, die speziell auf unser System
                abgepasst sind. Und das beste: Sie kriegen die Geräte auch gleich bei uns.
                <br />
                <br />
                Bei Fragen rufen Sie uns einfach an. Wir beraten Sie gern!
            </p>
        </CTA> -->
        <Pricing />
        <Contact />
        <!-- <Founders /> -->

        <SiteFooter />
    </div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader';
import HeroSection from './Landingpage/HeroSection';
import Solution from './Landingpage/Solution';
import Credentials from './Landingpage/Credentials';
import Features from './Landingpage/Features';
import CTA from './Landingpage/CTA.vue';
import SiteFooter from '@/components/SiteFooter';
import Pricing from './Landingpage/Pricing.vue';
import Contact from './Landingpage/Contact.vue';
import Testimonials from './Landingpage/Testimonials.vue';
import ProblemAwareness from './Landingpage/ProblemAwareness';
// import Founders from './Landingpage/Founders.vue';

export default {
    name: 'landing',
    components: {
        SiteHeader,
        HeroSection,
        ProblemAwareness,
        Solution,
        Credentials,
        Features,
        SiteFooter,
        // CTA,
        Pricing,
        Contact,
        // Testimonials,
        // Founders,
    },

    data() {
        return {
            isLoggedIn: false,
        };
    },
    methods: {},
    mounted() {
        document.querySelectorAll('a').forEach(a => {
            if (a.href.includes('#')) {
                a.addEventListener('click', function(e) {
                    e.preventDefault();
                    document.querySelector(a.getAttribute('href')).scrollIntoView({
                        behavior: 'smooth',
                    });
                });
            }
        });
    },
};
</script>
<style lang="scss">
body {
    width: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    font-size: 18px;
    color: var(--color-text-black);
    background-color: var(--color-bg);
    letter-spacing: 0.5px;

    .App {
        position: relative;

        line-height: 1.85em;
        font-family: font(outfit);

        .Wrapper {
            max-width: 1440px;
            padding: 0 40px;
            box-sizing: border-box;
            margin: 0 auto;
        }
    }
    @media (max-width: 1200px) {
        font-size: 16px;
    }
    @media (max-width: 500px) {
        font-size: 14px;
    }
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
*:focus {
    outline: none;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.Column {
    display: flex;
    gap: 10px;

    > * {
        flex: 1;
    }
}
</style>
