var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "InputWrap",
    class: [{
      'is-disabled': _vm.isDisabled
    }, {
      'is-checkbox': _vm.type === 'checkbox'
    }, {
      'is-password': _vm.type === 'password'
    }, {
      'has-error': _vm.error && _vm.error.length > 0
    }, "is-".concat(_vm.direction)],
    style: _vm.style
  }, [_c('label', {
    staticClass: "Label",
    attrs: {
      "for": _vm.label
    }
  }, [_vm._v(_vm._s(_vm.label))]), _vm.type === 'checkbox' ? _c('input', {
    staticClass: "Input",
    attrs: {
      "type": "checkbox",
      "name": _vm.label,
      "disabled": _vm.isDisabled
    },
    domProps: {
      "checked": _vm.value
    }
  }) : _vm.type === 'select' ? _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.mValue,
      expression: "mValue"
    }],
    staticClass: "Input",
    attrs: {
      "name": _vm.label,
      "disabled": _vm.isDisabled
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.mValue = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.options, function (option) {
    return _c('option', {
      key: option.value,
      domProps: {
        "value": option.value
      }
    }, [_vm._v(_vm._s(option.label) + " ")]);
  }), 0) : _vm.type === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.mValue,
      expression: "mValue"
    }],
    ref: "input",
    staticClass: "Input",
    attrs: {
      "pattern": _vm.pattern,
      "min": _vm.min,
      "max": _vm.max,
      "name": _vm.label,
      "disabled": _vm.isDisabled,
      "placeholder": _vm.placeholder,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.mValue) ? _vm._i(_vm.mValue, null) > -1 : _vm.mValue
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.$emit('onEnterPress');
      },
      "change": function change($event) {
        var $$a = _vm.mValue,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.mValue = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.mValue = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.mValue = $$c;
        }
      }
    }
  }) : _vm.type === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.mValue,
      expression: "mValue"
    }],
    ref: "input",
    staticClass: "Input",
    attrs: {
      "pattern": _vm.pattern,
      "min": _vm.min,
      "max": _vm.max,
      "name": _vm.label,
      "disabled": _vm.isDisabled,
      "placeholder": _vm.placeholder,
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.mValue, null)
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.$emit('onEnterPress');
      },
      "change": function change($event) {
        _vm.mValue = null;
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.mValue,
      expression: "mValue"
    }],
    ref: "input",
    staticClass: "Input",
    attrs: {
      "pattern": _vm.pattern,
      "min": _vm.min,
      "max": _vm.max,
      "name": _vm.label,
      "disabled": _vm.isDisabled,
      "placeholder": _vm.placeholder,
      "type": _vm.type
    },
    domProps: {
      "value": _vm.mValue
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.$emit('onEnterPress');
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.mValue = $event.target.value;
      }
    }
  }), _vm.error && _vm.error.length > 0 ? _c('span', {
    staticClass: "Error"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }