var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Dashboard"
  }, [_c('AppNavigation'), !_vm.hasFinishedLoading ? _c('Skeleton') : _vm._e(), _c('div', {
    staticClass: "Wrap"
  }, [!_vm.userVerified ? _c('Infobox', {
    attrs: {
      "hasCloseButton": false,
      "title": "Email Bestätigen",
      "message": "Sie haben eine Bestätigungs-E-Mail erhalten. Bitte klicken Sie auf den Link in dieser E-Mail, um Ihre Email-Adresse zu bestätigen."
    }
  }, [_c('div', {
    staticClass: "EmailVerifyActions"
  }, [_c('Button', {
    attrs: {
      "variant": "plain",
      "isLoading": _vm.isLoadingVerifyEmail,
      "size": "small"
    },
    on: {
      "onClick": _vm.handleVerifyEmailClick
    }
  }, [_vm._v(" Email Ändern ")]), _c('Button', {
    attrs: {
      "size": "small",
      "isLoading": _vm.isLoadingVerifyEmail
    },
    on: {
      "onClick": _vm.handleVerifyEmailClick
    }
  }, [_vm._v(" Email Erneut versenden ")])], 1)]) : _vm._e(), _c('transition-group', {
    staticStyle: {
      "display": "block",
      "overflow": "auto",
      "height": "100vh",
      "position": "relative"
    },
    attrs: {
      "name": _vm.transitionName,
      "as": "div",
      "mode": "out-in"
    }
  }, [_c('router-view', {
    key: "2",
    on: {
      "onFinishLoading": _vm.handleFinishedLoading
    }
  })], 1)], 1), _c('Modal', {
    attrs: {
      "show": _vm.isModalOpen,
      "size": "small",
      "title": 'Hallo ' + _vm.contactPerson,
      "action": {
        text: 'Los geht\'s',
        callback: _vm.closeModal
      },
      "cancel": {
        text: 'Abbrechen',
        callback: _vm.closeModal
      }
    },
    on: {
      "onModalClose": _vm.closeModal
    }
  }, [_c('p', [_vm._v(" Willkommen in Ihrem neuen Dashboard ✨ "), _c('br'), _c('br'), _vm._v(" TaxiFusion hat nun ein neues Aussehen. Doch auch unter der Haube hat sich einiges getan! Neben einem schnelleren System haben wir auch neue Funktionen eingeführt. So können Sie Falschanmeldungen z.B. nun selbst korrigieren. 📝 "), _c('br'), _c('br'), _vm._v(" Auch eine neue Fahrer-App 📱 ist nun verfügbar! Diese finden Sie "), _c('a', {
    staticStyle: {
      "color": "var(--color-blue-light)",
      "text-decoration": "underline"
    },
    attrs: {
      "href": "https://play.google.com/store/apps/details?id=com.taxifusion.driverApp",
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v(" hier")]), _vm._v(" für Android. "), _c('br'), _c('br'), _vm._v(" Ihr TaxiFusion-Team 🙋🏻‍♂️ ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }