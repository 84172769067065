<template>
    <div class="App">
        <BackArrow variant="white" />
        <div class="PrivacyPolicy">
            <div>
                <h2>Datenschutzerklärung</h2>

                <h3>1. Verantwortliche Stelle</h3>
                <p>
                    Verantwortlich für die Erhebung, Verarbeitung und Nutzung Ihrer
                    personenbezogenen Daten im Sinne des Bundesdatenschutzgesetzes und der DSGVO ist
                    die Nailed-IT Solutions UG (haftungsbeschränkt), Buckower Chaussee 110-113,
                    12277 Berlin.
                </p>

                <h3>2. Rechtsgrundlagen der Datenverarbeitung</h3>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten auf der Grundlage der folgenden
                    Rechtsgrundlagen gemäß Artikel 6 DSGVO:
                </p>
                <ul>
                    <li>
                        Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), wenn Sie dieser explizit
                        zugestimmt haben.
                    </li>
                    <li>
                        Zur Erfüllung eines Vertrags (Art. 6 Abs. 1 lit. b DSGVO) oder zur
                        Durchführung vorvertraglicher Maßnahmen.
                    </li>
                    <li>
                        Zur Erfüllung gesetzlicher Verpflichtungen (Art. 6 Abs. 1 lit. c DSGVO), z.
                        B. steuerliche Pflichten.
                    </li>
                    <li>
                        Zur Wahrung unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO),
                        wenn diese nicht Ihre Rechte und Freiheiten überwiegen.
                    </li>
                </ul>
                <h3>3. Erhebung und Verarbeitung personenbezogener Daten</h3>
                <p>
                    Wir erheben und verarbeiten personenbezogene Daten nur, soweit dies zur
                    Bereitstellung unserer Dienstleistungen erforderlich ist. Dies umfasst
                    insbesondere die Daten unserer Kunden, wie Name, E-Mail-Adresse, Telefonnummer
                    sowie steuer- und bankrelevante Daten.
                </p>

                <h3>4. Verwendung von Cookies und Tracking-Technologien</h3>
                <p>
                    Unsere Website verwendet Cookies und ähnliche Technologien, um die Nutzung
                    unserer Dienste zu ermöglichen und diese zur Verbesserung unserer Website zu
                    analysieren. Sie haben die Möglichkeit, Cookies über die entsprechenden
                    Einstellungen in Ihrem Browser zu akzeptieren oder abzulehnen. Genauere
                    Informationen über die Verwendung von Cookies finden Sie in unserer
                    <a href="/cookie-richtlinien">Cookie-Richtlinie</a>.
                </p>

                <h3>5. Zweck der Datenerhebung</h3>
                <p>
                    Die Verarbeitung Ihrer personenbezogenen Daten erfolgt zur Erfüllung des
                    Vertragsverhältnisses und zur Abwicklung von Zahlungen, zur Kommunikation mit
                    Ihnen sowie zur Einhaltung gesetzlicher Aufbewahrungspflichten und
                    steuerrechtlicher Vorgaben.
                </p>

                <h3>6. Weitergabe der Daten</h3>
                <p>
                    Ihre personenbezogenen Daten werden nicht an Dritte weitergegeben, es sei denn,
                    es ist zur Vertragserfüllung notwendig (z. B. Abwicklung von Zahlungen durch
                    Banken) oder wir sind gesetzlich dazu verpflichtet. Eine Datenübertragung an
                    Dritte in Nicht-EU-Länder erfolgt nur, wenn ausreichende Datenschutzniveaus
                    bestehen, z. B. EU-Standardvertragsklauseln.
                </p>

                <h3>7. Datensicherheit</h3>
                <p>
                    Ihre Daten werden durch moderne Sicherheitsstandards (z. B. SSL-Verschlüsselung)
                    geschützt, um einen unberechtigten Zugriff zu verhindern.
                </p>

                <h3>8. Ihre Rechte</h3>
                <p>
                    Sie haben die folgenden Rechte im Hinblick auf Ihre bei uns gespeicherten
                    personenbezogenen Daten:
                </p>
                <ul>
                    <li>
                        Recht auf Auskunft über Ihre von uns gespeicherten Daten (Art. 15 DSGVO).
                    </li>
                    <li>Recht auf Berichtigung unrichtiger Daten (Art. 16 DSGVO).</li>
                    <li>
                        Recht auf Löschung Ihrer Daten, sofern keine gesetzlichen
                        Aufbewahrungspflichten entgegenstehen (Art. 17 DSGVO).
                    </li>
                    <li>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO).</li>
                    <li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO).</li>
                    <li>Recht auf Widerspruch gegen die Verarbeitung (Art. 21 DSGVO).</li>
                    <li>Recht auf Widerruf einer erteilten Einwilligung (Art. 7 Abs. 3 DSGVO).</li>
                </ul>
                <h3>9. Aufbewahrungsdauer</h3>
                <p>
                    Wir speichern Ihre Daten so lange, wie dies zur Erfüllung unserer vertraglichen
                    oder gesetzlichen Pflichten notwendig ist. Nach Ablauf dieser
                    Aufbewahrungsfristen werden Ihre Daten unwiderruflich gelöscht.
                </p>

                <h3>10. Automatisierte Entscheidungsfindung und Profiling</h3>
                <p>
                    Eine automatisierte Entscheidungsfindung oder Profiling gemäß Artikel 22 DSGVO
                    findet nicht statt.
                </p>

                <h3>11. Änderungen der Datenschutzerklärung</h3>
                <p>
                    Wir behalten uns vor, diese Datenschutzerklärung jederzeit zu ändern. Die
                    jeweils aktuelle Fassung finden Sie auf unserer Website.
                </p>

                <h3>12. Kontakt</h3>
                <p>
                    Wenn Sie Fragen zum Datenschutz haben oder Ihre Rechte geltend machen möchten,
                    kontaktieren Sie uns bitte unter: kontakt@taxifusion.de.
                </p>

                <h4>Stand: 11. November 2024</h4>
            </div>
        </div>
        <SiteFooter />
    </div>
</template>

<script>
import BackArrow from '@/components/BackArrow.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
    components: {
        BackArrow,
        SiteFooter,
    },
};
</script>

<style lang="scss" scoped>
.PrivacyPolicy {
    max-width: 840px;
    padding: 120px 40px;
    box-sizing: border-box;
    margin: 0 auto;

    @media (max-width: breakpoint(tabletLandscape)) {
        padding: 40px 20px;
    }

    h3,
    h2,
    h4 {
        font-weight: bold;
        margin-top: 40px;
        margin-bottom: 10px;
    }

    h4 {
        margin-top: 40px;
        font-weight: bold;
    }

    p {
        font-size: 1.125rem;
    }

    a {
        color: #3498db; /* Example link color */
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
}
</style>
