var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "App"
  }, [_c('SiteHeader'), _c('HeroSection'), _c('ProblemAwareness'), _c('Solution'), _c('Credentials'), _c('Features'), _c('Pricing'), _c('Contact'), _c('SiteFooter')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }