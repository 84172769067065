<template>
    <div class="SiteButton" :class="sizeClass" @click="$emit('onClick')">
        <span><slot></slot></span>
    </div>
</template>

<script>
export default {
    name: 'SiteButton',
    props: {
        content: {
            default: '',
        },
        size: {
            type: String,
            default: '',
        },
    },
    computed: {
        sizeClass() {
            return this.size === 'small' ? 'is-small' : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.SiteButton {
    position: relative;
    border-radius: 5px;
    padding: 10px 45px;
    display: inline-block;
    background-size: 200%;
    box-shadow: 0 0px 0px 0px rgba(color(yellow-end), 1);
    transition: all 0.3s ease-out;
    transform: translateZ(0);
    cursor: pointer;
    border: 1px solid rgba(darken(color(yellow-dark), 10%), 0.3);
    background-color: color(yellow-start);

    &:hover {
        background-color: color(yellow-end);

        box-shadow: 0 0px 0px 4px rgba(color(yellow-end), 1);
    }

    span {
        font-weight: 700;
        color: var(--color-text-black);
        text-transform: uppercase;
        font-size: 1rem;
        transition: all 0.3s ease-out;
        transform: translateZ(0);
    }

    &.is-small {
        padding: 3px 20px;
        font-size: 0.95rem;
        span {
            font-size: 0.85em;
        }
    }
}
</style>
