<template>
    <div class="SiteHeader" :class="{ 'is-scrolled': isScrolled }">
        <div class="SiteHeader-Wrap">
            <a href="/" class="Logo"><img src="../assets/svg/logo.svg"/></a>
            <nav class="Navigation">
                <!-- Links for desktop, hidden on mobile -->
                <a class="NavigationItem isActive" href="#">TaxiFusion</a>
                <a class="NavigationItem" href="#features">Features</a>
                <a class="NavigationItem" href="#pricing">Preise</a>
                <a class="NavigationItem" href="#contact">Kontakt</a>
                <router-link class="NavigationItem" to="register">Registrieren</router-link>
                <router-link to="sign-in">
                    <SiteButton size="small">Login</SiteButton>
                </router-link>

                <div class="Hamburger" @click="toggleMobileMenu">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </nav>
        </div>

        <transition name="slide-in">
            <div v-if="isMobileMenuOpen" class="MobileMenu">
                <div class="MobileMenuClose" @click="closeMobileMenu">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path
                            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"
                        />
                    </svg>
                </div>
                <a href="#" @click="closeMobileMenu">TaxiFusion</a>
                <a href="#features" @click="closeMobileMenu">Features</a>
                <a href="#pricing" @click="closeMobileMenu">Preise</a>
                <a href="#contact" @click="closeMobileMenu">Kontakt</a>
                <router-link to="register" @click.native="closeMobileMenu"
                    >Registrieren</router-link
                >
                <router-link to="sign-in" @click.native="closeMobileMenu">Login</router-link>
            </div>
        </transition>
    </div>
</template>
<script>
import SiteButton from './SiteButton';
export default {
    name: 'SiteHeader',
    components: {
        SiteButton,
    },
    data() {
        return {
            isScrolled: false,
            isMobileMenuOpen: false, // Track hamburger menu open/closed state
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.isScrolled = window.scrollY > 0;
        },
        toggleMobileMenu() {
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
        },
        closeMobileMenu() {
            this.isMobileMenuOpen = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.SiteHeader {
    width: 100%;
    position: fixed;
    padding-top: 30px;
    z-index: 200;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease;
    background-color: transparent;

    @media (max-width: breakpoint(tabletPortrait)) {
        padding-top: 0px;
        padding: 20px;
    }

    &.is-scrolled {
        padding: 15px;
        background-color: var(--color-blue-darker);

        @media (max-width: breakpoint(tabletPortrait)) {
            padding: 15px 20px;
        }

        .Logo {
            width: 50px;
        }
    }

    .Logo {
        width: 70px;
        transition: all 0.3s ease;
        display: block;
    }

    .SiteHeader-Wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all 0.3s ease;
        @extend %sectionWrapper;

        @media (max-width: breakpoint(tabletPortrait)) {
            margin-bottom: 0;
            padding: 0;
            .Logo {
                width: 50px;
            }
        }

        .Navigation {
            display: flex;
            align-items: center;

            .NavigationItem {
                font-size: 16px;
                color: white;
                letter-spacing: 0.5px;
                text-decoration: none;
                margin-right: 30px;
                font-weight: 400;
                transition: all 0.3s ease;

                &:hover {
                    color: var(--color-text-white);
                }

                &.isActive {
                    color: white;
                }
            }

            @media (max-width: 768px) {
                .NavigationItem {
                    display: none;
                }
            }
        }

        .Hamburger {
            display: none;
            margin-left: 20px;
            cursor: pointer;
            &:hover {
                div {
                    margin: 6px 0;
                }
            }
            div {
                width: 30px;
                height: 2px;
                background-color: white;
                margin: 4px 0;
                transition: all 0.3s ease;
            }

            @media (max-width: breakpoint(tabletPortrait)) {
                display: block;
            }
        }
    }

    .MobileMenu {
        background: var(--color-blue-darker);
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 250px;
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        z-index: 300;
        transition: all 0.3s ease;

        /* Close Button Styling */
        .MobileMenuClose {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            width: 40px;
            height: 40px;

            svg {
                width: 100%;
                height: 100%;
                fill: white;
                transition: transform 0.3s ease;
            }

            &:hover svg {
                transform: rotate(90deg); /* Smooth rotation on hover */
            }
        }

        a {
            font-size: 18px;
            color: white;
            padding: 15px 20px;
            text-decoration: none;
            text-align: center;
            transition: background-color 0.3s ease;
            &:hover {
                background-color: var(--color-blue-light);
            }
        }
    }
}
.slide-in-enter-active,
.slide-in-leave-active {
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.slide-in-enter,
.slide-in-leave-to {
    transform: translateX(100%);
    opacity: 0;
}
</style>
