var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Skeleton",
    class: [{
      'with-background': _vm.withBackground
    }]
  }, [_c('div', {
    staticClass: "Skeleton-Row",
    style: {
      width: '70%',
      height: '80px',
      marginBottom: '60px'
    }
  }), _c('div', {
    staticClass: "Skeleton-Row",
    style: {
      width: '100%',
      height: '250px',
      marginBottom: '40px'
    }
  }), _vm._l(5, function (i) {
    return _c('div', {
      key: i,
      staticClass: "Skeleton-Row",
      style: {
        width: Math.min(Math.random() * 90 + 20, 100) + '%'
      }
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }