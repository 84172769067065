var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Section ProblemAwareness"
  }, [_c('h3', [_vm._v("Herausforderungen Als Unternehmer")]), _c('ul', [_c('li', [_c('strong', {
    staticClass: "is-highlighted"
  }, [_vm._v("Aufwändige Verwaltung")]), _vm._v(" durch unübersichtliche Software. ")]), _c('li', [_c('strong', {
    staticClass: "is-highlighted"
  }, [_vm._v("Hohe Kosten")]), _vm._v(" durch verpasste Termine, wie den P-Schein erneuern. ")]), _c('li', [_c('strong', {
    staticClass: "is-highlighted"
  }, [_vm._v("Einhaltung gesetzlicher Vorgaben")]), _vm._v(", wie z. B. INSIKA oder "), _c('strong', [_vm._v("TSE")]), _vm._v(". ")]), _c('li', [_c('strong', {
    staticClass: "is-highlighted"
  }, [_vm._v("Mangelnde Einsicht")]), _vm._v(" über Einnahmen und Fahrer-Performance. ")])]), _c('p', [_c('strong', {
    staticClass: "is-highlighted"
  }, [_vm._v("Diese Probleme")]), _vm._v(" behindern Ihre Effizienz und Rentabilität. "), _c('br'), _vm._v(" Zeit für Veränderungen. ")])]);
}]

export { render, staticRenderFns }