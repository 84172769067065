var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Footer"
  }, [_c('div', {
    staticClass: "Content"
  }, [_c('router-link', {
    staticClass: "Logo",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/svg/logo.svg"),
      "alt": "TaxiFusion Logo"
    }
  })]), _c('nav', {
    staticClass: "Navigation"
  }, [_vm._m(0), _c('div', {
    staticClass: "Col"
  }, [_c('h3', [_vm._v("taxiFusion")]), _c('router-link', {
    attrs: {
      "to": "/privacy-policy"
    }
  }, [_c('span', [_vm._v("Datenschutzerklärung")])]), _c('router-link', {
    attrs: {
      "to": "/terms-of-service"
    }
  }, [_c('span', [_vm._v("Geschäftsbedingungen")])]), _c('router-link', {
    attrs: {
      "to": "/impressum"
    }
  }, [_c('span', [_vm._v("Impressum")])])], 1)])], 1), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Copyright © TaxiFusion " + _vm._s(_vm.currentYear))])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Col"
  }, [_c('h3', [_vm._v("Produkt")]), _c('a', {
    attrs: {
      "href": "/#features"
    }
  }, [_c('span', [_vm._v("Features")])]), _c('a', {
    attrs: {
      "href": "/#pricing"
    }
  }, [_c('span', [_vm._v("Preise")])]), _c('a', {
    attrs: {
      "href": "/sign-in"
    }
  }, [_c('span', [_vm._v("Login")])])]);
}]

export { render, staticRenderFns }