var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    staticClass: "BackArrow",
    class: {
      'BackArrow--white': _vm.variant === 'white'
    },
    attrs: {
      "to": _vm.to
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "svg-inline": '',
      "role": 'presentation',
      "focusable": 'false',
      "tabindex": '-1'
    }
  }, [_c('path', {
    attrs: {
      "fill": "none",
      "d": "M0 0h24v24H0z"
    }
  }), _c('path', {
    attrs: {
      "fill": "currentColor",
      "d": "M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"
    }
  })]), _vm._v(" Zurück ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }