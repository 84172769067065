var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Section HeroSection"
  }, [_c('div', {
    staticClass: "Content"
  }, [_c('div', {
    staticClass: "Col"
  }, [_c('h1', [_vm._v(" Ihr zuverlässiger Partner für moderne Taxiverwaltung ")]), _c('span', {
    staticClass: "Subtitle"
  }, [_vm._v(" Die Softwarelösung für die Taxibranche von morgen. ")]), _c('br'), _c('router-link', {
    attrs: {
      "to": "/register"
    }
  }, [_c('SiteButton', [_vm._v(" Starten Sie Jetzt ")])], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }