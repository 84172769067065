<template>
    <div class="Section ProblemAwareness">
        <h3>Herausforderungen Als Unternehmer</h3>
        <ul>
            <li>
                <strong class="is-highlighted">Aufwändige Verwaltung</strong> durch unübersichtliche
                Software.
            </li>
            <li>
                <strong class="is-highlighted">Hohe Kosten</strong> durch verpasste Termine, wie den
                P-Schein erneuern.
            </li>
            <li>
                <strong class="is-highlighted">Einhaltung gesetzlicher Vorgaben</strong>, wie z. B.
                INSIKA oder <strong>TSE</strong>.
            </li>
            <li>
                <strong class="is-highlighted">Mangelnde Einsicht</strong> über Einnahmen und
                Fahrer-Performance.
            </li>
            <!-- <li>
                <strong class="is-highlighted">Unzufriedenheit der Fahrer</strong> wegen
                Fahrzeugausfällen.
            </li> -->
        </ul>
        <p>
            <strong class="is-highlighted">Diese Probleme</strong> behindern Ihre Effizienz und
            Rentabilität. <br />
            Zeit für Veränderungen.
        </p>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.ProblemAwareness {
    margin: 0 auto;
    @extend %sectionWrapper;
    background-color: var(--color-white);
    box-shadow: 14px 17px 38px 0 rgba(0, 0, 0, 0.17);
    border-radius: 10px;
    position: relative;
    z-index: 1;
    max-width: 800px;
    padding: 80px 60px 120px;

    @media (max-width: breakpoint(tabletPortrait)) {
        padding: 50px 20px 50px;
        margin: 10px;
        width: calc(100% - 20px);
    }

    h2 {
        margin: 0;
        padding-top: 0px;
        font-weight: 700;
    }

    ul {
        list-style-type: auto;
        padding: 0;
        margin: 60px 20px;
        text-align: left;

        li {
            margin: 14px 0;
            font-size: 1.25rem;
            position: relative;
            // list-style:;
        }
        li::before {
            position: absolute;
            left: -15px;
            top: 0;
            color: var(--color-red);
            font-size: 1.5rem;
        }
        strong {
            @media (max-width: breakpoint(tabletPortrait)) {
                &.is-highlighted {
                    display: block;
                }
            }
            &.is-highlighted {
                color: var(--color-red);
            }
        }
    }

    p {
        line-height: 1.5;
    }
}
</style>
