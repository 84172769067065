var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "AppFooter"
  }, [_c('div', {}, [_c('span', {
    staticClass: "Hint"
  }, [_vm._v("TaxiFusion © " + _vm._s(_vm.currentYear))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }