<template>
    <div class="App">
        <BackArrow variant="white" />
        <div class="TermsOfService">
            <div>
                <h2>Allgemeine Geschäftsbedingungen (AGB)</h2>

                <h3>1. Geltungsbereich</h3>
                <p>
                    Diese Allgemeinen Geschäftsbedingungen gelten für alle Verträge und
                    Dienstleistungen, die zwischen TaxiFusion und dem Kunden geschlossen werden.
                    Abweichende Regelungen sind nur gültig, wenn sie schriftlich vereinbart wurden.
                </p>

                <h3>2. Vertragsschluss</h3>
                <p>
                    Der Vertrag für die Nutzung der TaxiFusion-Software kommt zustande, wenn der
                    Kunde das Registrierungsformular vollständig ausgefüllt und wirksam an Nailed-IT
                    Solutions UG übermittelt hat und eine Bestätigung von Nailed-IT Solutions UG
                    erhalten hat.
                </p>

                <h3>3. Leistungen</h3>
                <p>
                    TaxiFusion bietet eine Softwarelösung zur Verwaltung von Taxiunternehmen an,
                    einschließlich Funktionen wie Schichtmanagement, Fahrzeugverwaltung und
                    Betriebsanalysen. Die genauen Funktionen der Software und etwaige Zusatzmodule
                    können auf der Website eingesehen werden.
                </p>

                <h3>4. Preise und Zahlungsbedingungen</h3>
                <p>
                    Die Nutzung der TaxiFusion-Software erfolgt gegen Zahlung der auf der Website
                    aufgeführten Gebühren. Zusätzliche Gebühren können für Sonderoptionen oder
                    Ergänzungsfunktionen anfallen. Die Abrechnung erfolgt monatlich, und die Preise
                    verstehen sich zzgl. MwSt.
                </p>

                <h3>4.1 Zahlungsmodalitäten</h3>
                <p>
                    Die Zahlung erfolgt per SEPA-Lastschriftverfahren, Kreditkarte oder anderer auf
                    der Website angegebener Zahlungsmethoden. Bei Zahlungsrückständen behalten wir
                    uns das Recht vor, den Zugang zur Software zu sperren.
                </p>

                <h3>5. Haftung</h3>
                <p>
                    TaxiFusion haftet für Schäden nur bei Vorsatz oder grober Fahrlässigkeit. Für
                    Datenverlust und entgangenen Gewinn wird keine Haftung übernommen, es sei denn,
                    dies wurde schriftlich festgelegt.
                </p>

                <h3>6. Kündigung</h3>
                <p>
                    Der Vertrag kann jederzeit ohne Fristen gekündigt werden, sofern keine
                    anderslautenden Vereinbarungen getroffen wurden. Eine Kündigung kann schriftlich
                    per E-Mail oder Post eingereicht werden.
                </p>

                <h3>7. Datenschutz</h3>
                <p>
                    Der Schutz Ihrer Daten ist uns wichtig. Alle Informationen zum Datenschutz
                    finden Sie in unserer Datenschutzerklärung.
                </p>

                <h3>7.1 Verarbeitung und Speicherung von Daten</h3>
                <p>
                    Alle personenbezogenen Daten werden gemäß den in der
                    <router-link to="/privacy-policy">Datenschutzerklärung</router-link>
                    definierten Zwecken verarbeitet. Die Daten werden nur so lange aufbewahrt, wie
                    es für die Erfüllung des Vertrages oder gesetzlich erforderlich ist.
                </p>

                <h3>8. Gerichtsstand und anwendbares Recht</h3>
                <p>
                    Es gilt das Recht der Bundesrepublik Deutschland. Gerichtsstand ist, soweit
                    gesetzlich zulässig, der Sitz von Nailed-IT Solutions UG.
                </p>

                <h3>9. Widerrufsrecht</h3>
                <p>
                    Wenn Sie Verbraucher gemäß § 13 BGB sind, steht Ihnen ein Widerrufsrecht zu. Sie
                    können Ihren Vertrag innerhalb von 14 Tagen ohne Angabe von Gründen widerrufen.
                    Um Ihr Widerrufsrecht auszuüben, müssen Sie uns, der Nailed-IT Solutions UG
                    (z.B. per Post oder E-Mail), eine eindeutige Erklärung über Ihren Entschluss
                    zukommen lassen, den Vertrag zu widerrufen.
                </p>

                <h3>10. Schlichtung und Streitbeilegung</h3>
                <p>
                    Im Falle von Streitigkeiten verpflichten sich die Parteien, zunächst eine
                    angemessene Schlichtung vor einer anerkannten Schlichtungsstelle (z. B.
                    Online-Streitbeilegungs-Plattform der EU) zu versuchen, bevor rechtliche
                    Schritte eingeschaltet werden. Weitere Informationen zur Online-Streitbeilegung
                    finden Sie unter der
                    <a href="https://ec.europa.eu/consumers/odr"
                        >EU-Plattform für Online-Streitbeilegung</a
                    >.
                </p>

                <h4>Stand: 11. November 2024</h4>
            </div>
        </div>
        <SiteFooter />
    </div>
</template>

<script>
import BackArrow from '@/components/BackArrow.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
    components: {
        BackArrow,
        SiteFooter,
    },
};
</script>

<style lang="scss" scoped>
.TermsOfService {
    max-width: 840px;
    padding: 120px 40px;
    box-sizing: border-box;
    margin: 0 auto;

    @media (max-width: breakpoint(tabletLandscape)) {
        padding: 40px 20px;
    }

    h3,
    h2,
    h4 {
        font-weight: bold;
        margin-top: 40px;
        margin-bottom: 10px;
    }

    h4 {
        margin-top: 40px;
        font-weight: bold;
    }

    p {
        font-size: 1.125rem;
    }

    a {
        color: #3498db; /* Example link color */
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
}
</style>
