<template>
    <router-link :to="to" class="BackArrow" :class="{ 'BackArrow--white': variant === 'white' }">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="none" d="M0 0h24v24H0z"/><path fill="currentColor" d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"/></svg>
        Zurück
    </router-link>
</template>

<script>
export default {
    name: 'BackArrow',
    props: {
        variant: {
            type: String,
            default: '',
        },
        to: {
            type: String,
            default: '/',
        },
    },
};
</script>

<style lang="scss">
.BackArrow {
    position: fixed;
    display: flex;
    align-items: center;
    top: 20px;
    left: 20px;
    z-index: 1;
    transition: all 0.3s ease;
    border-radius: 8px;
    padding: 10px 15px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 12px;
    text-decoration: none;

    @media (max-width: breakpoint(tabletPortrait)) {
        top: 20px;
        left: 0px;
    }

    &:hover {
        transform: translateX(-5px);
        background-color: rgba(255, 255, 255, 0.1);
    }

    &.BackArrow--white {
        background: white;
        color: black;
        box-shadow: 2px 10px 30px rgba(0, 0, 0, 0.1);
    }

    svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
}
</style>
