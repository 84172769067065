<template>
    <div class="Footer">
        <div class="Content">
            <router-link to="/" class="Logo">
                <img src="../assets/svg/logo.svg" alt="TaxiFusion Logo" />
            </router-link>
            <nav class="Navigation">
                <div class="Col">
                    <h3>Produkt</h3>
                    <a href="/#features"><span>Features</span></a>
                    <a href="/#pricing"><span>Preise</span></a>
                    <a href="/sign-in"><span>Login</span></a>
                </div>
                <div class="Col">
                    <h3>taxiFusion</h3>
                    <router-link to="/privacy-policy">
                        <span>Datenschutzerklärung</span>
                    </router-link>
                    <router-link to="/terms-of-service">
                        <span>Geschäftsbedingungen</span>
                    </router-link>
                    <router-link to="/impressum">
                        <span>Impressum</span>
                    </router-link>
                </div>
            </nav>
        </div>
        <span class="Hint">Copyright © TaxiFusion {{ currentYear }}</span>
    </div>
</template>
<script>
export default {
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
        isLandingPage() {
            console.log('is-landing', this.$route.path === '/');
            return this.$route.path === '/';
        },
    },
};
</script>

<style lang="scss">
.Footer {
    padding: 120px 30px 45px;
    color: var(--color-text-white);
    background: linear-gradient(-42deg, #474e7a 0%, #161936 100%);
    // background: darken(color(blue-dark), 0%);
    // background: var(--color-blue-dark);

    position: relative;
    z-index: 2;
    @media (max-width: breakpoint(tabletPortrait)) {
        padding: 35px 20px 25px;
    }

    > span {
        font-size: 14px;
        color: white;
        display: block;
        padding-top: 15px;
        @extend %sectionWrapper;
    }

    .Content {
        @extend %sectionWrapper;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0;

        @media (max-width: breakpoint(tabletPortrait)) {
            flex-direction: column;
            padding: 0;
        }
    }

    .Logo {
        width: 70px;

        @media (max-width: breakpoint(tabletPortrait)) {
            margin-bottom: 40px;
        }
    }

    .Navigation {
        display: flex;
        justify-content: space-between;

        @media (max-width: breakpoint(tabletPortrait)) {
            flex-direction: column;
            justify-content: flex-start;
        }

        .Col {
            flex: 1;
            max-width: 300px;
            padding: 0 50px;

            @media (max-width: breakpoint(tabletPortrait)) {
                padding: 30px 0;
            }
        }

        h3 {
            margin: 0;
            padding-bottom: 20px;
            color: white;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 18px;
        }

        a {
            display: block;
            text-decoration: none;
            margin-bottom: 8px;

            span {
                // text-transform: lowercase;
                font-size: 14px;
                color: white;
                position: relative;
                transition: all 0.3s ease;

                &::before {
                    display: block;
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    content: '';
                    height: 2px;
                    width: 100%;
                    transform: scaleX(0);
                    transition: all 0.3s ease;
                    background-color: var(--color-white);
                    transform-origin: left;
                }

                &.isActive,
                &:hover {
                    color: var(--color-text-white);

                    &::before {
                        transform: scaleX(0.4);
                    }
                }
            }
        }
    }

    .Hint {
        margin-bottom: 0;
        padding: 0;
    }
}
</style>
