var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ErrorPage"
  }, [_c('nav', {
    staticClass: "Navbar"
  }, [_c('Logo'), _c('router-link', {
    attrs: {
      "to": "sign-in"
    }
  }, [_c('SiteButton', [_vm._v(" Login ")])], 1)], 1), _c('img', {
    staticClass: "Error-image",
    attrs: {
      "src": "404.jpg",
      "alt": "404"
    }
  }), _c('div', {
    staticClass: "Error-404"
  }, [_c('div', {
    staticClass: "Error-404-content"
  }, [_c('h1', [_vm._v("Ooops!")]), _c('p', [_vm._v(" Die Seite konnte nicht gefunden werden. "), _c('br'), _vm._v(" Bitte überprüfen Sie die URL oder kehren Sie zur "), _c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("Startseite")]), _vm._v(" zurück. ")], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }