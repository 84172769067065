<template>
    <div class="Section SolutionSection">
        <h2>Die Lösung für Ihren Taxi Betrieb</h2>
        <p>
            <b>TaxiFusion</b> ist mehr als nur eine Verpflichtung zur Datenspeicherung.
            <br />
            <br />
            Unser Ziel ist es, Ihnen die Werkzeuge an die Hand zu geben, die Sie benötigen, um Ihr
            Geschäft effizienter und rentabler zu gestalten. Mit unserer benutzerfreundlichen
            Software sparen Sie wertvolle Zeit und Ressourcen, die Sie in den Ausbau Ihres
            Unternehmens investieren können.
            <br />
            <br />
            Es geht um die effiziente Verwaltung Ihres ganzen Unternehmens mit einer einzigen
            benutzerfreundlichen Plattform.
            <br />
        </p>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.SolutionSection {
    @extend %sectionWrapper;
    border-radius: 10px;
    margin: 120px auto 85px;
    padding: 0 40px;
    max-width: calc(1200px - 80px);
    position: relative;
    z-index: 1;

    &::after {
        position: absolute;
        content: '';
        height: 100%;
        width: 4px;
        background-color: var(--color-green);
        top: 0;
        left: 0;
    }

    @media (max-width: breakpoint(tabletPortrait)) {
        padding: 0px 20px 0px;
        width: calc(100% - 20px);
    }

    h2 {
        margin: 0;
        padding-bottom: 30px;
        padding-top: 0;
        font-weight: 700;
    }

    p {
        color: color((text-black));
    }
}
</style>
