<template>
    <div class="Section">
        <div class="Contact-Wrap MessageSend" v-if="hasSendMessage">
            <div class="Contact" ref="messageSend">
                <div class="SectionHeader">
                    <h2>Ihre Nachricht wurde geschickt</h2>
                    <p>Wir werden uns schnellstmöglich bei Ihnen melden.</p>
                </div>
            </div>
        </div>
        <div class="Contact-Wrap" v-else>
            <div class="Contact" id="contact">
                <div class="SectionHeader">
                    <p>Immer für Sie da</p>
                    <h2>Kontaktieren Sie uns</h2>
                </div>
                <p>
                    Wir freuen uns auf Ihre Nachricht und werden uns schnellstmöglich bei Ihnen
                    melden.
                </p>
                <form @submit.prevent="submitForm" class="ContactForm">
                    <div class="FormRow">
                        <div class="InputWrap">
                            <label for="name">Name</label>
                            <input
                                class="Input"
                                type="text"
                                id="name"
                                v-model="form.name"
                                required
                            />
                        </div>
                        <div class="InputWrap">
                            <label for="email">Email Adresse</label>
                            <input
                                class="Input"
                                type="email"
                                id="email"
                                v-model="form.email"
                                required
                            />
                            <!--                            <span class="Hint">Damit wir Ihnen antworten können.</span>-->
                        </div>

                        <div class="InputWrap">
                            <label for="message">Nachricht</label>
                            <textarea
                                class="Input"
                                id="message"
                                v-model="form.message"
                                required
                                @input="updateCharacterCount"
                            ></textarea>
                            <span class="Hint" v-if="characterCount < minCharacterCount">
                                Mindestens {{ minCharacterCount }} Zeichen lang ({{
                                    characterCount
                                }}/{{ minCharacterCount }} Zeichen)</span
                            >
                        </div>
                        <input type="text" name="cat" v-model="form.cat" class="cat" />
                    </div>
                    <Button type="submit" class="SubmitButton" size="large" :isLoading="isSending"
                        >Nachricht senden
                    </Button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import Button from '@/components/widgets/Button';

export default {
    components: {
        Button,
    },
    data() {
        return {
            isSending: false,
            hasSendMessage: false,
            characterCount: 0,
            minCharacterCount: 20,
            form: {
                name: '',
                email: '',
                message: '',
                cat: '',
            },
        };
    },
    methods: {
        updateCharacterCount() {
            // Split the message by spaces and filter out empty strings
            this.characterCount = this.form.message.length;
        },
        async submitForm() {
            if (this.form.message.length <= this.minCharacterCount) {
                this.$toasted.error('Ihre Nachricht ist zu kurz');
                // return;
            }

            if (this.form.cat.length > 0) return;

            try {
                this.isSending = true;
                await axios.post(`${process.env.VUE_APP_API_BASE_URL}/contact`, this.form);
                this.isSending = false;
                this.$toasted.success(
                    'Vielen Dank für Ihre Nachricht! Wir werden uns so schnell wie möglich bei Ihnen melden.',
                );

                this.hasSendMessage = true;
                this.$nextTick(() => {
                    const messageSend = this.$refs.messageSend;
                    messageSend.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'nearest',
                    });
                });
            } catch (error) {
                this.isSending = false;
                this.$toasted.error(
                    'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
                );
            }
        },
    },
};
</script>

<style lang="scss">
.Contact-Wrap {
    padding: 40px;
    padding-bottom: 120px;
    background-color: white;

    @media (max-width: breakpoint(tabletPortrait)) {
        padding: 20px;
    }

    &.MessageSend {
        padding: 120px 0;
    }

    .Contact {
        border-radius: 10px;
        max-width: 800px;
        margin: 80px auto;

        .SectionHeader {
            margin: 30px 0 80px;
            text-align: center;

            @media (max-width: breakpoint(tabletPortrait)) {
                text-align: left;
            }

            p {
                font-size: 1.125rem;
            }
        }

        h2 {
            font-weight: bold;
            margin: 0px;
        }

        .ContactForm {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 40px;

            @media (min-width: 600px) {
            }

            .FormRow {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }

            .InputWrap {
                display: flex;
                flex-direction: column;

                label {
                    font-weight: bold;
                    margin-bottom: 5px;
                    line-height: 1.5;
                }

                .Input,
                textarea {
                    padding: 10px;
                    border-radius: 5px;
                    border: 1px solid #ddd;
                    background: white;

                    &:focus {
                        outline: none;
                        border-color: color(blue-light);
                    }
                }

                textarea {
                    min-height: 100px;
                }
            }
        }

        .Hint {
            font-size: 12px;
            color: #666;
            margin-top: -5px;
        }

        .SubmitButton {
            @media (min-width: 600px) {
                grid-column: span 2;
            }
        }

        .cat {
            visibility: hidden;
            opacity: 0;
        }
    }
}
</style>
