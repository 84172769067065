<template>
    <div class="Section CTA" :class="[`CTA--${size}`]">
        <div>
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: 'medium',
        },
    },
};
</script>

<style lang="scss">
.CTA {
    padding: 120px 75px;
    hyphens: auto;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;

    &.CTA--small {
        padding: 50px 20px;
        @media (max-width: breakpoint(tabletPortrait)) {
            padding: 50px 20px;
        }
    }

    &.CTA--large {
        padding: 150px 75px 150px;
        @media (max-width: breakpoint(tabletPortrait)) {
            padding: 50px 20px;
        }
    }
    @media (max-width: breakpoint(tabletPortrait)) {
        padding: 50px 20px;
    }

    > div {
        max-width: 1000px;
        margin: 0 auto;
        width: 100%;
    }

    p {
        width: 100%;
        line-height: 1.75;
    }

    h2 {
        width: 100%;
        font-weight: 200;
        margin: 0;
        padding-bottom: 30px;
        padding-top: 0px;
        line-height: 1.25;

        span {
            font-weight: 700;
        }
    }
    a {
        text-decoration: underline;

        @media screen and (max-width: breakpoint(mobile)) {
            text-decoration: underline;
        }
    }
}
</style>
