<template>
    <div class="Card" :class="`is-${size}`">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: 'medium',
        },
    },
};
</script>

<style lang="scss">
.Card {
    background: var(--color-white);
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    padding: 40px 50px;
    border-radius: 5px;
    border: 1px solid var(--color-border);

    @media screen and (max-width: breakpoint(tabletPortrait)) {
        padding: 20px;
    }

    &.is-small {
        padding: 20px 30px;
    }
}
</style>
