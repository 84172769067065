<template>
    <div class="AppFooter">
        <div class="">
            <span class="Hint">TaxiFusion © {{ currentYear }}</span>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
    },
};
</script>
<style lang="scss">
.AppFooter {
    @extend %contentWrapper;
    color: var(--color-text-gray);
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    margin: 0;
    margin-bottom:10px;
    padding: 0;
    @media screen and (max-width: breakpoint(tabletPortrait)) {
        display: none;
    }

    svg {
        width: 0.875rem;
        height: 0.875rem;
        margin-left: 5px;
    }

    a {
        transition: all 0.3s;
        color: var(--color-text-gray);
        margin-right: 15px;

        &:hover {
            color: var(--color-text-gray-dark);
        }
    }

    > div {
        display: flex;
        align-items: center;
        flex-direction: row;
    }
}
</style>
