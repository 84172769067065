var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Section Features",
    attrs: {
      "id": "features"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "Content"
  }, [_c('div', {
    staticClass: "Feature"
  }, [_c('div', {
    staticClass: "Text"
  }, [_c('h3', [_vm._v("Die Taxisoftware von morgen")]), _c('div', {
    staticClass: "VideoWrap"
  }, [_c('video', {
    ref: "featureVideo",
    staticClass: "Video",
    attrs: {
      "autoplay": "",
      "muted": "",
      "controls": "",
      "playsinline": "",
      "poster": "/product/dashboard.jpg"
    },
    domProps: {
      "muted": true
    }
  }, [_c('source', {
    attrs: {
      "src": "/product/tf-demo.mp4",
      "type": "video/mp4"
    }
  })])]), _vm._m(1)])]), _vm._m(2), _vm._m(3), _c('div', {
    staticClass: "Feature Feature--App"
  }, [_c('img', {
    attrs: {
      "src": "/product/mobile.png",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "Text"
  }, [_c('h3', [_vm._v("Die App für den Fahrer")]), _c('p', [_vm._v(" Die Fahrer-App ermöglicht es den Fahrern, auf wichtige Informationen zuzugreifen und ihre Fahrten effizient zu verwalten. Mit Echtzeit-Statistiken und Zugriff auf Betriebsdaten können Fahrer ihre Leistung verbessern. Die App ist benutzerfreundlich gestaltet und bietet den Fahrern eine nahtlose Integration in das Gesamtsystem. ")]), _c('div', {
    staticClass: "AppStoreIcons"
  }, [_c('a', {
    ref: "noopener noreferrer",
    attrs: {
      "href": "https://apps.apple.com/de/app/taxifusion-driver-app/id6523420111?l=en-GB",
      "target": "_blank"
    }
  }, [_c('img', {
    staticStyle: {
      "border": "1px solid white",
      "border-radius": "10px"
    },
    attrs: {
      "src": "/app-store-icon.png",
      "alt": "Apple App Store",
      "height": "50"
    }
  })]), _c('a', {
    ref: "noopener noreferrer",
    attrs: {
      "href": "https://play.google.com/store/apps/details?id=com.taxifusion.driverApp",
      "target": "_blank"
    }
  }, [_c('img', {
    staticStyle: {
      "border": "1px solid white",
      "border-radius": "10px"
    },
    attrs: {
      "src": "/google-play-icon.png",
      "alt": "Google Play Store",
      "height": "50"
    }
  })])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', [_vm._v("Wir "), _c('span', [_vm._v("vereinfachen")]), _vm._v(" alle Ihre Aufgaben")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" Umfassende Managementlösung, die speziell für die Anforderungen und Abläufe von Taxiunternehmen entwickelt wurde. Durch die intuitive Benutzeroberfläche und leistungsfähige Analysewerkzeuge können Sie Ihren Betrieb effizienter und transparenter gestalten. "), _c('br'), _c('br'), _vm._v(" Die Plattform integriert alle notwendigen Funktionen, von der Fahrzeugverfolgung bis hin zur Umsatzanalyse, in einem einzigen, leicht zugänglichen System. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "Features-Title"
  }, [_vm._v("Auf einem "), _c('span', [_vm._v("Blick")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Features-Grid"
  }, [_c('div', {
    staticClass: "Feature-Item"
  }, [_c('i', {
    staticClass: "ri-focus-line"
  }), _c('h3', [_vm._v("Übersicht über Ihr Unternehmen")]), _c('p', [_vm._v(" Erhalten Sie detaillierte Einblicke und Analysen über Ihr Unternehmen, um fundierte Entscheidungen zu treffen. ")])]), _c('div', {
    staticClass: "Feature-Item"
  }, [_c('i', {
    staticClass: "ri-smartphone-line"
  }), _c('h3', [_vm._v("Integrierte Fahrer-App")]), _c('p', [_vm._v(" Ermöglichen Sie Ihren Fahrern den Zugriff auf Fahrtdaten und Optimierung der Routen direkt über ihr Smartphone. ")])]), _c('div', {
    staticClass: "Feature-Item"
  }, [_c('i', {
    staticClass: "ri-cloud-line"
  }), _c('h3', [_vm._v("Internetbasierte Verwaltung")]), _c('p', [_vm._v(" Verwalten Sie Ihr Geschäft von überall aus mit einer Cloud-Plattform, die Ihnen maximale Flexibilität bietet. ")])]), _c('div', {
    staticClass: "Feature-Item"
  }, [_c('i', {
    staticClass: "ri-shield-check-line"
  }), _c('h3', [_vm._v("Erfüllung gesetzlicher Anforderungen")]), _c('p', [_vm._v(" Bleiben Sie immer auf dem neuesten Stand der gesetzlichen Bestimmungen und vermeiden Sie rechtliche Probleme. ")])]), _c('div', {
    staticClass: "Feature-Item"
  }, [_c('i', {
    staticClass: "ri-brain-line"
  }), _c('h3', [_vm._v("Über 30 Jahre Erfahrung")]), _c('p', [_vm._v(" Das System wurde mit direktem Input und Erfahrungen von Taxiunternehmern und -fahrern entwickelt, um sicherzustellen, dass es den Bedürfnissen der Kunden entspricht. ")])]), _c('div', {
    staticClass: "Feature-Item"
  }, [_c('i', {
    staticClass: "ri-pie-chart-line"
  }), _c('h3', [_vm._v("Advanced Analyse")]), _c('p', [_vm._v(" Greifen Sie auf fortgeschrittene Analysetools zu, um die Leistung Ihres Unternehmens genau zu verstehen und zu verbessern. ")])])]);
}]

export { render, staticRenderFns }