var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Section CTA",
    class: ["CTA--".concat(_vm.size)]
  }, [_c('div', [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }